import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams, useNavigate } from 'react-router-dom';
import { Container, Grid, Typography, Button, CircularProgress, Box } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { toast } from 'react-toastify';
import { useCart } from './CartContext';
import CustomButton from './CustomButton';

const ProductDetail = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [details, setDetails] = useState(null);

  const { addToCart } = useCart(); // Get the addToCart function from context

  useEffect(() => {
    fetchDetails(id);
  }, [id]);

  const fetchDetails = async (id) => {
    try {
      const response = await axios.get(`https://eggccessories.com/api/products/${id}`);
      setDetails(response.data);
    } catch (error) {
      console.error('Error fetching product details', error);
    }
  };

  if (!details) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        minHeight="100vh"
        bgcolor="#31473A"
      >
        <CircularProgress color="secondary" />
      </Box>
    );
  }

  const handleAddToCart = () => {
    if (details) {
      addToCart(details); // Use the context function to add item to the cart
      toast.success('Item added to cart!');
    }
  };

  return (
  <div style={{ backgroundColor: '#E7E1CC', minHeight: '100vh', padding: '20px' }}>
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      minHeight="100vh"
      px={2}
      py={4}
    >
      <Container maxWidth="lg">
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <CustomButton text="Back To Products" icon={<ArrowBackIcon />} onClick={() => navigate('/productList')} />
          </Grid>
          <Grid item xs={12} md={8}>
            <img src={details.imageUrl} alt={details.name} style={{ width: '100%', borderRadius: '8px' }} />
          </Grid>
          <Grid item xs={12} md={4}>
            <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center">
              <Typography variant="h4" gutterBottom>
                {details.name}
              </Typography>
              <Typography variant="body1" paragraph>
                {details.description}
              </Typography>
              <Typography variant="body1" gutterBottom>
                £{details.price}
              </Typography>
              <Box mt={2}>
                <CustomButton text="Add to Cart" onClick={handleAddToCart} />
              </Box>
              <Box mt={2}>
                <CustomButton text="View My Cart" link="/cart" />
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
    </div>
  );
};

export default ProductDetail;
