import React, { useState } from 'react';
import { useStripe, useElements, PaymentElement, AddressElement } from '@stripe/react-stripe-js';
import { Button, Typography, Box, CircularProgress } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useAuth } from './AuthContext';
import { useCart } from './CartContext';

const CheckoutForm = () => {
  const stripe = useStripe();
  const elements = useElements();
  const navigate = useNavigate();
  const { user } = useAuth();
  const { cart, totalPrice, resetCart } = useCart();
  const [error, setError] = useState(null);
  const [isProcessing, setIsProcessing] = useState(false);
  const [succeeded, setSucceeded] = useState(false);
  const [address, setAddress] = useState({});

  const handleAddressChange = (event) => {
    if (event.complete) {
      setAddress(event.value);
    } else {
      setAddress({});
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    console.log('Form submit event triggered'); // Log to verify submission

    if (!stripe || !elements) {
      console.error('Stripe or Elements not initialized');
      return;
    }

    setIsProcessing(true);
    setError(null);
    console.log("getting past the if statement")

    try {
      const { error, paymentIntent } = await stripe.confirmPayment({
        elements,
        confirmParams: {
          return_url: 'https://eggccessories.com/complete',
          shipping: {
            address: {
              line1: address.line1,
              city: address.city,
              state: address.state,
              postal_code: address.postal_code,
              country: address.country,
            },
          },
        },
        redirect: 'if_required',
      });

      console.log('Payment response:', { error, paymentIntent }); // Log payment response

      if (error) {
        setError(error.message);
        setIsProcessing(false);
      } else if (paymentIntent && paymentIntent.status === 'succeeded') {

        console.log('Payment succeeded, sending order completion request'); // Log payment success

        const orderDetails = {
            paymentId: paymentIntent.id,
            email: user.email,
            address: address,
            totalCost: totalPrice,
            cartItems: cart
        }
        try {
          const response = await fetch('https://eggccessories.com/complete-order', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify(orderDetails),
          });

          const responseText = await response.text();
          console.log('Fetch response:', responseText); // Log fetch response

          if (!response.ok) {
            console.error('Failed to complete order: ${responseText}');
          } else {
            console.log('Order completion request successful');
            for (const item of cart) {
                        try {
                          const deleteResponse = await fetch(`http://localhost:8080/cart/${item.id}`, {
                            method: 'DELETE',
                          });

                          if (!deleteResponse.ok) {
                            console.error(`Failed to delete item with ID ${item.id}`);
                          } else {
                            console.log(`Item with ID ${item.id} deleted successfully`);
                          }
                        } catch (deleteError) {
                          console.error(`Error deleting item with ID ${item.id}:`, deleteError);
                        }
                      }
            resetCart();
            navigate(`/complete?payment_intent=${paymentIntent.id}&payment_intent_client_secret=${paymentIntent.client_secret}&redirect_status=succeeded`);
          }
        } catch (fetchError) {
          console.error('Error sending order completion request:', fetchError);
        }
      } else {
        console.warn('Payment Intent not successful or status unknown');
      }
    } catch (error) {
      console.error('Error during payment processing:', error);
      setError('An unexpected error occurred. Please try again.');
      setIsProcessing(false);
    } finally {
      setIsProcessing(false);
    }
  };


  return (
    <Box component="form" onSubmit={handleSubmit} sx={{ width: '100%', maxWidth: 600 }}>
      <PaymentElement />
      <AddressElement options={{ mode: 'shipping' }} onChange={handleAddressChange} />
      {error && (
        <Typography color="error" variant="body1" sx={{ mt: 2 }}>
          {error}
        </Typography>
      )}
      <Button
        type="submit"
        variant="contained"
        color="primary"
        sx={{
          mt: 4,
          backgroundColor: "#36A49A",
          "&:hover": {
            backgroundColor: '#2f8f87',
          },
        }}
        disabled={isProcessing || succeeded}
      >
        {isProcessing ? <CircularProgress size={24} color="inherit" /> : 'Pay Now'}
      </Button>
    </Box>
  );
};

export default CheckoutForm;

