import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from './AuthContext';  // Import the useAuth hook
import { toast } from 'react-toastify';

const Crud = () => {
  const { user } = useAuth();  // Get the user from AuthContext
  const navigate = useNavigate();

  const allowedEmails = ['Johnnymashu@gmail.com']

  useEffect(() => {
    console.log('User in Crud page:', user);  // Log the user to see if it's available
    if (!user) {
        console.log("error")
        navigate("/")
    }
//      if (!toast.isActive('auth-toast')) { // Check if toast is already active
//              toast.error('Please sign in to go to checkout', { toastId: 'auth-toast' });
//            }
//            setTimeout(() => {
//              navigate('/'); // Redirect after a short delay
//            }, 100)}; // Adjust delay as needed
  }, [user]);

  return (
    <div>
      {user ? (
        <div>Welcome, {user.email}</div>  // Display user's email if available
      ) : (
        <div>No user logged in.</div>
      )}
    </div>
  );
};

export default Crud;
