import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Container, Typography, Box, Grid, List, ListItem, ListItemText, Divider, CircularProgress } from '@mui/material';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { useCart } from './CartContext';
import { useAuth } from './AuthContext'; // Import the useAuth hook
import CheckoutForm from './CheckoutForm';
import { toast } from 'react-toastify';
import Banner from './Banner.js';

const test_pk = "pk_test_51PqgNQLrmUg65mhaYvsJ04Sl2fuWvaIseXTwLBQTj5EACkqQwJdefpr9haMEc5bBvUIZ77tvHDidXCGxrYrPWd0V006WxpHH59";
const live_pk = "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";
const stripePromise = loadStripe(live_pk);

const Checkout = () => {
  const [clientSecret, setClientSecret] = useState('');
  const [loading, setLoading] = useState(true);
  const { cart, totalPrice } = useCart();
  const { user } = useAuth(); // Get user from context
  const navigate = useNavigate();

  useEffect(() => {
    if (!user) {
      if (!toast.isActive('auth-toast')) { // Check if toast is already active
        toast.error('Please sign in to go to checkout', { toastId: 'auth-toast' });
      }
      setTimeout(() => {
        navigate('/SignInOptions'); // Redirect after a short delay
      }, 100); // Adjust delay as needed
      return;
    }

    const fetchClientSecret = async () => {
      setLoading(true);
      try {
        const response = await fetch('https://eggccessories.com/create-payment-intent', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            amount: totalPrice * 100, // Convert totalPrice to smallest currency unit (e.g., cents)
            email: user.email, // Pass the user's email from authentication context
          }),
        });

        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const data = await response.json();
        setClientSecret(data.clientSecret); // Set the clientSecret
      } catch (error) {
        console.error('Error fetching client secret:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchClientSecret();
  }, [totalPrice, user]);

  if (loading) {
    return (
    <div style={{ backgroundColor: '#E7E1CC', minHeight: '100vh', padding: '20px' }}>
      <Container sx={{ py: 5 }}>
        <Typography variant="h4" component="h1" gutterBottom>
          Checkout
        </Typography>
        <Box sx={{ textAlign: 'center', mt: 4 }}>
          <CircularProgress color="primary" />
        </Box>
      </Container>
      </div>
    );
  }

  return (
    <div style={{ backgroundColor: '#E7E1CC', minHeight: '100vh', padding: '20px' }}>
      <Banner text="Please note we only deliver within the UK for now" height='100px' backgroundColor="#E7E1CC" />
      <Container sx={{ py: 5 }}>
        <Typography variant="h4" component="h1" gutterBottom>
          Checkout
        </Typography>
        <Grid container spacing={4}>
          <Grid item xs={12} md={6}>
            <Box>
              <Typography variant="h6" gutterBottom>
                Order Summary
              </Typography>
              <Typography variant="body1" paragraph>
                Total Price: £{totalPrice.toFixed(2)}
              </Typography>
              <List>
                {cart.map((product, index) => (
                  <React.Fragment key={index}>
                    <ListItem>
                      <Grid container spacing={2} alignItems="center">
                        <Grid item xs={8}>
                          <ListItemText
                            primary={product.name}
                            secondary={`Price: £${product.price}`}
                          />
                        </Grid>
                        <Grid item xs={4}>
                          {product.imageUrl ? (
                            <img
                              src={product.imageUrl}
                              alt={product.description || 'Product Image'}
                              style={{ width: '100%', height: 'auto', borderRadius: '8px' }}
                              onError={(e) => {
                                e.target.onerror = null;
                                e.target.src = 'https://via.placeholder.com/150';
                              }}
                            />
                          ) : (
                            <Typography variant="body2">No Image Available</Typography>
                          )}
                        </Grid>
                      </Grid>
                    </ListItem>
                    {index < cart.length - 1 && <Divider />}
                  </React.Fragment>
                ))}
              </List>
            </Box>
          </Grid>
          <Grid item xs={12} md={6}>
            {clientSecret ? (
              <Elements stripe={stripePromise} options={{ clientSecret }}>
                <CheckoutForm />
              </Elements>
            ) : (
              <Box sx={{ textAlign: 'center', mt: 4 }}>
                <CircularProgress color="primary" />
              </Box>
            )}
          </Grid>
        </Grid>
      </Container>
    </div>
  );
}

export default Checkout;
