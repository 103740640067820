import React, { useState } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { useAuth } from './AuthContext'; // Import your custom AuthProvider hook
import { toast } from 'react-toastify';
import CustomButton from './CustomButton.js';
import {
  Container,
  TextField,
  Button,
  Typography,
  Paper,
  Divider,
  Box,
  Alert,
  Link,
} from '@mui/material';

const SignInPage = () => {
  const { signInWithGoogle, signInWithEmail, signUpWithEmail, sendPasswordResetEmail } = useAuth();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [isLogin, setIsLogin] = useState(true);
  const [error, setError] = useState(null);
  const [showResetPassword, setShowResetPassword] = useState(false);
  const navigate = useNavigate();

  const handleEmailSignInOrSignUp = () => {
    setError(null);
    setShowResetPassword(false); // Reset this state on new attempt

    if (isLogin) {
      signInWithEmail(email, password)
        .then(() => {
          navigate("/");
          toast.success("Sign in Successful");
        })
        .catch((error) => {
          if (error.code === 'auth/invalid-credential') {
            setError('Incorrect password.');
            setShowResetPassword(true);  // Show reset password button
          } else if (error.code === 'auth/user-not-found') {
            setError('No account found with this email.');
          } else {
            setError(error.message);  // Handle other errors
          }
        });
    } else {
      signUpWithEmail(email, password)
        .then(() => {
          navigate("/");
          toast.success("Successfully created an account and signed in");
        })
        .catch((error) => {
          setError(error.message);  // Handle other errors
        });
    }
  };


  const handleResetPassword = () => {
    if (!email) {
      toast.error("Please enter a valid email address.");
      return;
    }
    sendPasswordResetEmail(email)
      .then(() => {
        toast.success("Password Reset Email sent.");
      })
      .catch((error) => {
        setError(error.message);  // Handle any errors related to reset
      });
  }

  return (
<div style={{ backgroundColor: '#E7E1CC', minHeight: '100vh', padding: '20px' }}>
    <Container component="main" maxWidth="xs">
      <Paper elevation={3} sx={{ p: 4, mt: 8, textAlign: 'center',  }}>
        <Typography variant="h5" component="h2" gutterBottom>
          {isLogin ? 'Sign in to your account' : 'Create a new account'}
        </Typography>

        {error && (
          <Alert severity="error" sx={{ mb: 2 }}>
            {error}
          </Alert>
        )}

        <form onSubmit={(e) => e.preventDefault()}>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            label="Email Address"
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            label="Password"
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            onClick={handleEmailSignInOrSignUp}
            sx={{ mt: 2, mb: 2 }}
          >
            {isLogin ? 'Sign in with Email' : 'Sign Up with Email'}
          </Button>
        </form>

        {showResetPassword && (
          <CustomButton
            text="Reset Password"
            onClick={handleResetPassword}
            sx={{ mt: 2 }}
          />
        )}



        <Box mt={2}>
          <Typography variant="body2">
            {isLogin ? (
              <>
                Don't have an account?{' '}
                <Link
                  href="#"
                  onClick={() => setIsLogin(false)}
                  underline="hover"
                  color="primary"
                >
                  Sign up
                </Link>
              </>
            ) : (
              <>
                Already have an account?{' '}
                <Link
                  href="#"
                  onClick={() => setIsLogin(true)}
                  underline="hover"
                  color="primary"
                >
                  Sign in
                </Link>
              </>
            )}
          </Typography>
        </Box>
      </Paper>
    </Container>
    </div>
  );
};

export default SignInPage;
