import React from 'react';
import { Link } from 'react-router-dom';
import { Box } from "@mui/material"; // Import Box from Material-UI
import ownerImage from './Images/Eggccessories_logo.png'; // Replace with your image path
import CustomCard from './CustomCard.js';
import PictureRotator from './PictureRotator.js';
import Banner from './Banner';
import video from './Images/Egi_Crochet.mp4';
import poster from './Images/IMG_7405.jpeg'
import LazyLoadedVideo from './LazyLoadedVideo.js'

function Owner() {
  return (
    <div>
         <PictureRotator />

      <Banner text="Eggccessories started off as its own small business in Manchester. We specialized in cute crocheted accessories with an emphasis on making them one of a kind and with high quality recycled materials." description="Local Focus, Uplifting smaller crocheting scene in Manchester" height='250px' backgroundColor="#E7E1CC"  />

       <LazyLoadedVideo
              src={video}
              poster={poster}
              controls={true} // Enable controls if needed
              style={{ height: '600px' }} // Adjust as necessary
            />

    </div>
  );
}

export default Owner;
