import React, { useState } from 'react';
import {
  AppBar, Toolbar, IconButton, Typography, Button, Badge, Tooltip,
  Menu, MenuItem, Box, Drawer, List, ListItem, ListItemText, Divider
} from '@mui/material';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import logo from './Images/Eggccessories-logo.png';
import { ShoppingCart, AccountCircle, Menu as MenuIcon } from '@mui/icons-material';
import { useCart } from './CartContext';
import { useAuth } from './AuthContext';

const Header = () => {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const { cartItemCount } = useCart();
  const [anchorEl, setAnchorEl] = useState(null);
  const { signIn, user, signOut } = useAuth();
  const navigate = useNavigate();

  const toggleDrawer = (open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setDrawerOpen(open);
  };

  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleSignOut = () => {
    signOut();
    navigate('/'); // Redirect to home after sign out
    handleMenuClose();
  };

  const handleSignInOtherAccount = () => {
    navigate("/SignInOptions");
    handleMenuClose();
  };

  // Drawer content for mobile view
  const drawerContent = (
    <Box sx={{ width: 250 }} role="presentation" onClick={toggleDrawer(false)} onKeyDown={toggleDrawer(false)}>
      <List>
        <ListItem button component={RouterLink} to="/">
          <ListItemText primary="Home" />
        </ListItem>
        <ListItem button component={RouterLink} to="/productList">
          <ListItemText primary="Catalog" />
        </ListItem>
        <ListItem button component={RouterLink} to="/Inquiry">
          <ListItemText primary="Inquiry" />
        </ListItem>
        <ListItem button component={RouterLink} to="/cart">
          <ListItemText primary="Cart" />
          <Badge badgeContent={cartItemCount} color="secondary" />
        </ListItem>
        <Divider />
        {user ? (
          <>
            <ListItem button onClick={handleSignOut}>
              <ListItemText primary="Sign Out" />
            </ListItem>
            <ListItem button onClick={handleSignInOtherAccount}>
              <ListItemText primary="Swap Accounts" />
            </ListItem>
          </>
        ) : (
          <ListItem button component={RouterLink} to="/SignInOptions">
            <AccountCircle />
            <ListItemText primary="Sign In" />
          </ListItem>
        )}
      </List>
    </Box>
  );

  return (
    <>
      <AppBar position="sticky" sx={{ backgroundColor: '#E7E1CC', boxShadow: 'none' }}>
        <Toolbar sx={{ display: 'flex', justifyContent: 'space-between' }}>
          {/* Mobile menu button */}
          <IconButton
            edge="start"
            color="inherit"
            aria-label="menu"
            onClick={toggleDrawer(true)}
            sx={{ display: { xs: 'block', sm: 'none' } }}
          >
            <MenuIcon />
          </IconButton>

          {/* Logo */}
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <img
              src={logo}
              alt="Eggccessories Logo"
              style={{ maxHeight: '50px', marginRight: '20px' }}
            />
            <Typography
              variant="h6"
              component={RouterLink}
              to="/"
              color="inherit"
              sx={{
                color: '#333',
                textDecoration: 'none',
                '&:hover': {
                  '&::after': {
                    width: '100%',
                    left: '0',
                  },
                },
                position: 'relative',
                '&::after': {
                  content: '""',
                  position: 'absolute',
                  bottom: '0',
                  left: '50%',
                  height: '2px',
                  width: '0%',
                  backgroundColor: '#333',
                  transition: 'width 0.3s ease, left 0.3s ease',
                },
              }}
            >
              Eggccessories
            </Typography>
          </Box>

          {/* Desktop links */}
          <Box sx={{ display: { xs: 'none', sm: 'flex' }, flexGrow: 1, justifyContent: 'flex-end', alignItems: 'center' }}>
            <Badge badgeContent={cartItemCount} color="secondary">
              <Button color="inherit" aria-label="Link to My Cart" component={RouterLink} to="/cart">
                <ShoppingCart sx={{ color: '#333' }} />
              </Button>
            </Badge>
            {['Home', 'Catalog', 'Inquiry'].map((text, index) => (
              <Button
                key={text}
                color="inherit"
                component={RouterLink}
                to={text === 'Home' ? '/' : text === 'Catalog' ? '/productList' : `/${text.toLowerCase()}`}
                sx={{
                  position: 'relative',
                  textDecoration: 'none',
                  color: '#333',
                  '&:hover': {
                    '&::after': {
                      width: '100%',
                      left: '0',
                    },
                  },
                  '&::after': {
                    content: '""',
                    position: 'absolute',
                    bottom: '0',
                    left: '50%',
                    height: '2px',
                    width: '0%',
                    backgroundColor: '#333',
                    transition: 'width 0.3s ease, left 0.3s ease',
                  },
                }}
              >
                {text}
              </Button>
            ))}

            {user ? (
              <>
                <Tooltip title={`Signed in as ${user.displayName || user.email}`} arrow>
                  <span>
                    <Button color="inherit" onClick={handleMenuClick} aria-label="View Profile" sx={{ color: 'black' }}>
                      <AccountCircle sx={{ color: '#333' }} />
                      My Profile
                    </Button>
                  </span>
                </Tooltip>
                <Menu
                  anchorEl={anchorEl}
                  open={Boolean(anchorEl)}
                  onClose={handleMenuClose}
                >
                  <MenuItem onClick={handleSignInOtherAccount}>Swap Accounts</MenuItem>
                  <MenuItem onClick={handleSignOut}>Sign Out</MenuItem>
                </Menu>
              </>
            ) : (
              <Button color="inherit" component={RouterLink} to="/SignInOptions">
                <AccountCircle sx={{ color: '#333' }} />
              </Button>
            )}
          </Box>
        </Toolbar>
      </AppBar>

      {/* Drawer for mobile menu */}
      <Drawer
        anchor="left"
        open={drawerOpen}
        onClose={toggleDrawer(false)}
      >
        {drawerContent}
      </Drawer>
    </>
  );
};

export default Header;
