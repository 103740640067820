import React from "react";
import LazyLoadedVideo from './LazyLoadedVideo'; // Import the LazyLoadedVideo component

const Banner = ({
  text,
  caption,
  height = "300px",
  mediaType,
  mediaUrl,
  backgroundColor = "#ffffff",
  textColor = "#000000",
  captionColor = "#000000",
  lazyLoad = false, // New lazyLoad prop
  poster // Add poster for the video
}) => {
  return (
    <div
      style={{
        width: "100%",
        height: height,
        backgroundColor: backgroundColor,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        overflow: "hidden",
        position: "relative",
        textAlign: "center",
      }}
    >
      {mediaType === "image" && mediaUrl ? (
        <img
          src={mediaUrl}
          alt="Banner"
          style={{
            width: "100%",
            height: "100%",
            objectFit: "cover",
          }}
        />
      ) : mediaType === "video" && mediaUrl ? (
        lazyLoad ? ( // Use LazyLoadedVideo if lazyLoad is true
          <LazyLoadedVideo
            videoUrl={mediaUrl}
            poster={poster} // Pass the poster image for the video
            height={height}
            width="100%"
          />
        ) : (
          <video
            src={mediaUrl}
            controls
            style={{
              width: "100%",
              height: "100%",
              objectFit: "cover",
            }}
            autoPlay
            muted
            playsInline
          />
        )
      ) : null}

      {text && (
        <div
          style={{
            color: textColor,
            fontSize: "2rem",
            zIndex: 1,
            padding: "0 20px",
          }}
        >
          {text}
        </div>
      )}

      {caption && (
        <div
          style={{
            color: captionColor,
            fontSize: "1.5rem",
            zIndex: 1,
            padding: "0 20px",
            marginTop: "10px",
          }}
        >
          {caption}
        </div>
      )}
    </div>
  );
};

export default Banner;
