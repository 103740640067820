import React, { useState, useEffect } from 'react';

const LazyLoadedVideo = ({ src, poster, controls = false, style }) => {
  const [isLoaded, setIsLoaded] = useState(false);
  const [videoRef, setVideoRef] = useState(null); // Ref to the video element

  // Lazy load the video when it is in view
  const handleScroll = () => {
    const videoElement = document.getElementById(`lazy-video-${src}`);
    if (videoElement) {
      const { top, bottom } = videoElement.getBoundingClientRect();
      const inView = top < window.innerHeight && bottom > 0;
      if (inView) {
        setIsLoaded(true);
        window.removeEventListener('scroll', handleScroll);
      }
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  // Handle play button click
  const handlePlay = () => {
    if (videoRef) {
      videoRef.play(); // Play the video when user clicks the play button
    }
  };

  return (
    <div id={`lazy-video-${src}`} style={{ position: 'relative' }}>
      {isLoaded ? (
        <video
          ref={setVideoRef}
          src={src}
          controls={controls} // Show controls only if needed
          style={{
            width: '100%',
            height: '100%',
            objectFit: 'cover',
            ...style,
          }}
          muted // Optional: mute the video
        />
      ) : (
        <img
          src={poster}
          alt="Video Poster"
          style={{ width: '100%', height: '100%', objectFit: 'cover', cursor: 'pointer' }}
          onClick={handlePlay} // Play video on poster click
        />
      )}
    </div>
  );
};

export default LazyLoadedVideo;
