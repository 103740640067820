import React, { useState, useEffect, useRef } from 'react';
import { CCarousel, CCarouselItem, CImage, CCarouselCaption } from '@coreui/react';
import '@coreui/coreui/dist/css/coreui.min.css';
import { Box, Typography, useMediaQuery } from "@mui/material";
import { keyframes } from '@emotion/react';
import { Link } from 'react-router-dom';

// Define keyframes for the materialize effect
const materialize = keyframes`
  0% {
    opacity: 0;
    transform: translateY(30px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
`;

const PictureRotator = () => {
  const [isTextVisible, setIsTextVisible] = useState(false);
  const [isCarouselVisible, setIsCarouselVisible] = useState(false);

  const textRef = useRef(null);
  const carouselRef = useRef(null);

  // Use media query to detect mobile screen size
  const isMobile = useMediaQuery('(max-width:600px)');

  useEffect(() => {
    const observerOptions = { threshold: 0.8 };

    const textObserver = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          setIsTextVisible(true);
          textObserver.unobserve(entry.target);
        }
      });
    }, observerOptions);

    const carouselObserver = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          setIsCarouselVisible(true);
          carouselObserver.unobserve(entry.target);
        }
      });
    }, observerOptions);

    if (textRef.current) {
      textObserver.observe(textRef.current);
    }

    if (carouselRef.current) {
      carouselObserver.observe(carouselRef.current);
    }

    return () => {
      if (textRef.current) textObserver.unobserve(textRef.current);
      if (carouselRef.current) carouselObserver.unobserve(carouselRef.current);
    };
  }, []);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: isMobile ? 'column' : 'row', // Column on mobile, row on larger screens
        justifyContent: isMobile ? 'center' : 'space-between',
        alignItems: isMobile ? 'center' : 'flex-start', // Align items at the start on larger screens
        backgroundColor: "#E7E1CC",
        padding: '20px',
        height: '100%',
        boxSizing: 'border-box',
      }}
    >
      {/* Text Section */}
      <Box
        ref={textRef}
        sx={{
          maxWidth: isMobile ? '100%' : '400px', // Adjust text width for mobile
          opacity: isTextVisible ? 1 : 0,
          animation: isTextVisible ? materialize + ' 1s ease forwards' : 'none',
          textAlign: isMobile ? 'center' : 'left',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          height: '100%', // Use full height of the container
          flexGrow: 1, // Allow this Box to take up available space
        }}
      >
        <Box>
          <Typography variant="h3" gutterBottom>
            Discover Eggccessories
          </Typography>
          <Typography variant="h6" paragraph>
            Explore our handmade collection of cute crocheted accessories themed around fashion, kitchens, and anything else we felt like making. Please check the {' '}
            <Link to="/productlist" style={{ textDecoration: 'underline', color: '#000' }}>
              catalog
            </Link> to see our full selection, and make use of the filters in the sidebar to narrow your search!
          </Typography>
        </Box>

        {/* New Paragraph in Bottom Right */}
        <Box
          sx={{
            textAlign: 'left', // Align text to the right for the second paragraph
            opacity: isTextVisible ? 1 : 0,
            animation: isTextVisible ? materialize + ' 1s ease forwards' : 'none',
            marginTop: 'auto', // Push this paragraph to the bottom
          }}
        >

          <Typography variant="h3" gutterBottom>
            What's with Egg?
          </Typography>
          <Typography variant="h6" paragraph>
            Egi or 아기 is Korean for baby, and it's what I call my partner who crochets these products. I thought it would be fitting to incorporate it into our name since it embodies the cute and unique vibe we want in our accessories.
          </Typography>
        </Box>
      </Box>

      {/* Carousel Section */}
      <div
        className="carousel-container"
        ref={carouselRef}
        style={{
          width: isMobile ? '100%' : '600px', // Adjust carousel width for mobile
          opacity: isCarouselVisible ? 1 : 0,
          animation: isCarouselVisible ? materialize + ' 1s ease forwards' : 'none',
          transition: 'opacity 1s ease',
        }}
      >
        <CCarousel controls transition="crossfade">
          {/* Carousel Items */}
          <CCarouselItem>
            <CImage className="d-block w-100" src="https://eggccessories-bucket.s3.eu-north-1.amazonaws.com/Pink_Scrunchy.jpg" alt="slide 1" />
            <CCarouselCaption className="d-none d-md-block">
              <h1>Fashion Accessories</h1>
              <p>Our Pink Scrunchy made of 100% recycled cotton</p>
            </CCarouselCaption>
          </CCarouselItem>
          <CCarouselItem>
            <CImage className="d-block w-100" src='https://eggccessories-bucket.s3.eu-north-1.amazonaws.com/Green_White_Checkered_Coaster.jpeg' alt="slide 2" />
            <CCarouselCaption className="d-none d-md-block">
              <h1>Kitchen Accessories</h1>
              <p>Green and White Checkered Coaster made of 100% cotton</p>
            </CCarouselCaption>
          </CCarouselItem>
          <CCarouselItem>
            <CImage className="d-block w-100" src='https://eggccessories-bucket.s3.eu-north-1.amazonaws.com/red_bookmark_heart_on_book.jpeg' alt="slide 3" />
            <CCarouselCaption className="d-none d-md-block">
              <h1>Random Accessories</h1>
              <p>Cute Red Heart Shaped Bookmarks made of 100% cotton</p>
            </CCarouselCaption>
          </CCarouselItem>
        </CCarousel>
      </div>
    </Box>
  );
};

export default PictureRotator;
