import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Drawer, List, ListItem, ListItemText, ListItemIcon, IconButton, Typography, Divider, TextField, InputAdornment } from '@mui/material';
import { Menu, Countertops, Checkroom, EmojiNature, FilterList, Search as SearchIcon } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';

function Sidebar({ onFilterChange, onSearchChange }) {
  const [open, setOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [selectedFilter, setSelectedFilter] = useState('');
  const navigate = useNavigate();

  const toggleDrawer = useCallback((isOpen) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setOpen(isOpen);
  }, []);

  const filters = [
    { text: 'Fashion', filter: 'Fashion', icon: <Checkroom /> },
    { text: 'Kitchen', filter: 'Kitchen', icon: <Countertops /> },
    { text: 'Other', filter: 'other', icon: <EmojiNature /> },
    { text: 'Clear Filters', filter: '', icon: <FilterList /> }
  ];

  const handleFilterClick = (filter) => {
    setSearchQuery(''); // Clear the search bar
    setSelectedFilter(filter);
    navigate(`?theme=${filter}`); // Use navigate to update URL and trigger navigation
    applyFilters(filter, ''); // Apply filters with cleared search query
    setOpen(false); // Close the sidebar
  };

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault(); // Prevent default behavior (e.g., form submission)
      applyFilters(selectedFilter, searchQuery); // Apply filters with the current search query
      setOpen(false); // Close the sidebar
    }
  };

  const applyFilters = useCallback((filter, search) => {
    if (onFilterChange) {
      onFilterChange(filter);
    }
    if (onSearchChange) {
      onSearchChange(search);
    }
  }, [onFilterChange, onSearchChange]);

  const list = () => (
    <div
      role="presentation"
      onClick={(e) => e.stopPropagation()}
      style={{ width: 250 }}
    >
      <div style={{ padding: '16px', textAlign: 'center' }}>
        <Typography variant="h6">Filter By</Typography>
      </div>
      <Divider />
      <div style={{ padding: '16px' }}>
        <TextField
          fullWidth
          variant="outlined"
          placeholder="Search..."
          value={searchQuery}
          onChange={handleSearchChange} // Update search query as you type
          onKeyPress={handleKeyPress} // Trigger search on Enter key
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
          sx={{ marginBottom: 2 }}
        />
      </div>
      <List>
        {filters.map((item, index) => (
          <ListItem button key={index} onClick={() => handleFilterClick(item.filter)}>
            <ListItemIcon>
              {item.icon}
            </ListItemIcon>
            <ListItemText primary={item.text} />
          </ListItem>
        ))}
      </List>
    </div>
  );

  return (
    <div>
      <IconButton
        edge="start"
        color="inherit"
        aria-label="menu"
        onClick={toggleDrawer(true)}
        style={{ color: '#000000', paddingLeft: '20px' }}
      >
        <Menu />
      </IconButton>
      <Drawer anchor="left" open={open} onClose={toggleDrawer(false)}>
        {list()}
      </Drawer>
    </div>
  );
}

Sidebar.propTypes = {
  onFilterChange: PropTypes.func.isRequired,
  onSearchChange: PropTypes.func,
};

export default Sidebar;
