import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { Box, CssBaseline } from '@mui/material';
import { Helmet } from 'react-helmet';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './App.css';


import Header from './Header.js';
import Footer from './Footer.js';
import About from './About.js';
import Owner from './Owner.js';
import ProductList from './ProductList.js';
import ProductDetail from './ProductDetail.js';
import Cart from './Cart.js';
import StylishForm from './StylishForm.js';
import Checkout from './Checkout.js';
import logo from './Images/Eggccessories-logo.png';
import { CartProvider } from './CartContext';
import { AuthProvider } from './AuthContext';
import Completion from './Completion.js';
import SignInOptions from './SignInOptions.js';
import ResetPasswordPage from './ResetPasswordPage.js';
import Crud from './Crud.js';

const test_pk = "pk_test_51PqgNQLrmUg65mhaYvsJ04Sl2fuWvaIseXTwLBQTj5EACkqQwJdefpr9haMEc5bBvUIZ77tvHDidXCGxrYrPWd0V006WxpHH59";
const live_pk = "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";
const stripePromise = loadStripe(live_pk);

function App() {
  return (
  <AuthProvider>
  <CartProvider>
    <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                minHeight: '100vh', // Full viewport height
              }}
            >
      <ToastContainer/>
      <Router>
        <Header />

        <Box sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column', width: '100%', backgroundColor: '#E7E1CC' }}>
        <Elements stripe={stripePromise}>
          <Routes>
            <Route path="/" element={
              <>
                <Helmet>
                    <title>Eggccessories | Sustainable Handcrafted Accessories by Egi</title>
                    <meta name="description" content="Discover Eggccessories, your local Manchester shop for unique, handcrafted crocheted accessories made from high-quality recycled materials. Join 아기 (Egi) in this creative adventure." />
                    <meta property="og:title" content="Eggccessories | Handcrafted Accessories by Egi" />
                    <meta property="og:image" content="https://eggccessories-bucket.s3.eu-north-1.amazonaws.com/Eggccessories-logo.png" />
                    <meta property="og:url" content="https://eggccessories.com" />
                    <meta property="og:type" content="website" />


                    <link rel="canonical" href="https://eggccessories.com"/>

                </Helmet>
                <script type="application/ld+json">
                        {JSON.stringify({
                          "@context": "https://schema.org",
                          "@type": "AboutPage",
                          "name": "About Eggccessories",
                          "description": "Eggccessories is a sustainable brand creating handcrafted crocheted accessories using high-quality recycled materials.",
                          "url": "https://eggccessories.com",
                          "mainEntity": {
                            "@type": "Organization",
                            "name": "Eggccessories",
                            "url": "https://eggccessories.com",
                            "logo": "https://eggccessories-bucket.s3.eu-north-1.amazonaws.com/Eggccessories-logo.png",
                            "sameAs": [
                              "https://www.instagram.com/eggccessories__/?igsh=bWEwcGFmY2c0cmRr&utm_source=qr"
                            ],
                            "description": "Eggccessories specializes in sustainable, handcrafted crocheted accessories made by Egi."
                          }
                        })}
                      </script>

                <About />
                <Owner />

              </>
            } />
            <Route path="/productList" element={
            <>
                <Helmet>
                    <title>Eggccessories | Catalog of all of our Recycled Accessories</title>
                    <meta name="description" content="Explore all of our handcrafted crocheted accessories with this intuitive catalog. Search or filter with our sidebar, and find the perfect eggccessory for you!" />
                    <meta property="og:title" content="product catalog" />
                    <meta property="og:image" content="https://eggccessories-bucket.s3.eu-north-1.amazonaws.com/Eggccessories-logo.png" />
                    <meta property="og:url" content="https://eggccessories.com/productList" />
                    <meta property="og:type" content="website" />

                    <link rel="canonical" href="https://eggccessories.com/productList" />
                </Helmet>

            <ProductList />
            </>
            } />
            <Route path="/product/:id" element={
            <>
            <Helmet>
                    <title>Eggccessories | Sustainable Handcrafted Accessories made by Egi</title>
                    <meta name="description" content="Explore our growing collection of accessories each hand crafted by 아기 (Egi)" />
                    <meta property="og:title" content="Eggccessories | Handcrafted Accessories by Egi" />
                    <meta property="og:image" content="https://eggccessories-bucket.s3.eu-north-1.amazonaws.com/Eggccessories-logo.png" />

                    <meta property="og:type" content="product" />

                    <link rel="canonical" href={"https://eggccessories.com/product/${window.location.pathname.split("/").pop()}"} />
            </Helmet>
            <ProductDetail />
            </>
            } />
            <Route path="/Inquiry" element={
            <>
                <Helmet>
                    <title>Eggccessories | Make an Inquiery or Leave a Review</title>
                    <meta name="description" content="If you loved our eggccessoires or have any tips on the product or site please leave a review. If you want to join us in the early stages of this adventure please reach out as well!" />
                    <meta property="og:title" content="Eggccessories | Inquiries and Reviews" />
                    <meta property="og:image" content="https://eggccessories-bucket.s3.eu-north-1.amazonaws.com/Eggccessories-logo.png" />
                    <meta property="og:url" content="https://eggccessories.com/Inquiry" />
                    <meta property="og:type" content="website" />
                    <link rel="canonical" href="https://eggccessories.com/Inquiry" />
                </Helmet>
                {/* Schema Markup for Inquiry */}
                                        <script type="application/ld+json">
                                          {JSON.stringify({
                                            "@context": "https://schema.org",
                                            "@type": "ContactPage",
                                            "name": "Inquiry",
                                            "description": "Make an inquiry or leave a review for our handcrafted accessories.",
                                            "url": "https://eggccessories.com/Inquiry",
                                            "mainEntity": {
                                              "@type": "Organization",
                                              "name": "Eggccessories",
                                              "url": "https://eggccessories.com",
                                              "logo": "https://eggccessories-bucket.s3.eu-north-1.amazonaws.com/Eggccessories-logo.png",
                                              "contactPoint": {
                                                "@type": "ContactPoint",
                                                "telephone": "++44-7716-733951", // Add a contact number
                                                "contactType": "Customer Service"
                                              }
                                            }
                                          })}
                                        </script>
            <StylishForm />
            </>
            } />
            <Route path="/Checkout" element={
                <>
                   <Helmet>
                    <title>Eggccessories | Checkout</title>
                    <meta name="description" content="Checkout your Cart" />
                    <meta property="og:title" content="Eggccessories | Checkout" />
                    <meta property="og:image" content="https://eggccessories-bucket.s3.eu-north-1.amazonaws.com/Eggccessories-logo.png" />
                    <meta property="og:url" content="https://eggccessories.com/Checkout" />
                    <meta property="og:type" content="website" />
                    <link rel="canonical" href="https://eggccessories.com/Checkout" />
                   </Helmet>
                   <Checkout />
                </>
            } />
                <Route path="/Cart" element={
                <>
                   <Helmet>
                    <title>Eggccessories | Cart</title>
                    <meta name="description" content="View Cart" />
                    <meta property="og:title" content="Eggccessories | Cart" />
                    <meta property="og:image" content="https://eggccessories-bucket.s3.eu-north-1.amazonaws.com/Eggccessories-logo.png" />
                    <meta property="og:url" content="https://eggccessories.com/Cart" />
                    <meta property="og:type" content="website" />
                        <link rel="canonical" href="https://eggccessories.com/Cart" />
                   </Helmet>
                   <Cart />
                </>
            } />
            <Route path="/complete" element={
                            <>
                               <Helmet>
                                <title>Eggccessories | Payment Completion</title>
                                <meta name="description" content="Payment Completion Page" />
                                <meta property="og:title" content="Eggccessories | Payment Completion" />
                                <meta property="og:image" content="https://eggccessories-bucket.s3.eu-north-1.amazonaws.com/Eggccessories-logo.png" />
                                <meta property="og:url" content="https://eggccessories.com/complete" />
                                <meta property="og:type" content="website" />
                                    <link rel="canonical" href="https://eggccessories.com/complete" />
                               </Helmet>
                               <Completion />
                            </>
                        } />
            <Route path="/SignInOptions" element={
                            <>
                               <Helmet>
                                 <title>Eggccessories | Sign In</title>
                                <meta name="description" content="Options to Sign In" />
                                <meta property="og:title" content="Eggccessories | Sign In" />
                                <meta property="og:image" content="https://eggccessories-bucket.s3.eu-north-1.amazonaws.com/Eggccessories-logo.png" />
                                <meta property="og:url" content="https://eggccessories.com/SignInOptions" />
                                <meta property="og:type" content="website" />
                                    <link rel="canonical" href="https://eggccessories.com/SignInOptions" />
                               </Helmet>
                               <SignInOptions />
                            </>
                        } />
            <Route path="/PasswordReset" element={
                            <>
                               <Helmet>
                                <title>Eggccessories | Password reset</title>
                                <meta name="description" content="Reset Your Password" />
                                <meta property="og:title" content="Eggccessories | Password Reset" />
                                <meta property="og:image" content="https://eggccessories-bucket.s3.eu-north-1.amazonaws.com/Eggccessories-logo.png" />
                                <meta property="og:url" content="https://eggccessories.com/PasswordReset" />
                                <meta property="og:type" content="website" />
                                    <link rel="canonical" href="https://eggccessories.com/PasswordReset" />
                               </Helmet>
                               <ResetPasswordPage />
                            </>
                        } />
            <Route path="/Crud" element={
                                        <>
                                           <Helmet>
                                            <title>Eggccessories | Crud functionality Form Page for Admins</title>
                                            <meta name="description" content="Add products" />
                                            <meta property="og:title" content="Eggccessories | Crud Form" />
                                            <meta property="og:image" content="https://eggccessories-bucket.s3.eu-north-1.amazonaws.com/Eggccessories-logo.png" />
                                            <meta property="og:url" content="https://eggccessories.com/Crud" />
                                            <meta property="og:type" content="website" />
                                                <link rel="canonical" href="https://eggccessories.com/Crud" />
                                           </Helmet>
                                           <Crud />
                                        </>
                                    } />
          </Routes>
          </Elements>
        </Box>

        <Footer />
      </Router>
    </Box>
    </CartProvider>
    </AuthProvider>
  );
}

export default App;




