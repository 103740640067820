import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Link as RouterLink } from 'react-router-dom';
import { Grid, Card, CardActionArea, CardContent, CardMedia, Typography, Container, CssBaseline, Box } from '@mui/material';
import SideBar from './SideBar.js';
import { useLocation } from 'react-router-dom';
import { keyframes } from '@emotion/react';
import { css } from '@emotion/react';
import { useCart } from './CartContext.js';

// Define keyframes for the bounce effect
const bounce = keyframes`
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-10px);
  }
  100% {
    transform: translateY(0);
  }
`;

const ProductList = () => {
  const [products, setProducts] = useState([]);
  const [filter, setFilter] = useState('');
  const [searchQuery, setSearchQuery] = useState('');
  const location = useLocation();
  const { cart } = useCart();

  useEffect(() => {
    const query = new URLSearchParams(location.search);
    const filterParam = query.get('theme') || '';
    setFilter(filterParam);
    fetchProducts(filterParam, searchQuery);
  }, [location.search, searchQuery]);

  const fetchProducts = async (filter = '', searchQuery = '') => {
    try {
      const response = await axios.get('https://eggccessories.com/api/products', {
        params: { theme: filter, search: searchQuery }
      });
      console.log('Fetched products:', response.data);
      setProducts(response.data);
    } catch (error) {
      console.error('Error fetching products:', error);
    }
  };

  const handleFilterChange = (filter) => {
    setFilter(filter);
    fetchProducts(filter, searchQuery);
  };

  const handleSearchChange = (query) => {
    setSearchQuery(query);
    fetchProducts(filter, query);
  };

  const cartItemIds = new Set(cart.map(item => item.id));

  return (
  <>
  <h1 style={{ textAlign: 'center', marginBottom: '20px', color: '#333' }}>
   Explore our eggccessories, use the filters on the left if needed</h1>
    <div style={{ display: 'flex', minHeight: '100vh', backgroundColor: '#E7E1CC' }}>

      <SideBar onFilterChange={handleFilterChange} onSearchChange={handleSearchChange} />
      <Box
        component="main"
        sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column', padding: '20px' }}
      >
        <Container maxWidth="xl" sx={{ padding: 0 }}>
          <Grid container spacing={3}>
            {products
              .filter(product => !cartItemIds.has(product.id))
              .map((product) => (
                <Grid item key={product.id} xs={12} sm={6} md={4} lg={3}>
                  <Card
                    sx={{
                      backgroundColor: '#EDF4F2',
                      display: 'flex',
                      flexDirection: 'column',
                      transition: 'transform 0.5s ease',
                      '&:hover': {
                        animation: `${bounce} 0.5s ease`,
                      },
                    }}
                  >
                    <CardActionArea
                      component={RouterLink}
                      to={`/product/${product.id}`}
                      sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}
                    >
                      <CardMedia
                        component="img"
                        alt={product.name}
                        image={product.imageUrl}
                        title={product.name}
                        sx={{
                          height: '80%',  // Takes most of the card's height
                          objectFit: 'cover',
                          width: '100%',
                        }}
                      />
                      <CardContent sx={{ padding: '8px', display: 'flex', justifyContent: 'space-between' }}>
                        <Typography variant="h6" component="h2" sx={{ fontSize: '1rem' }}>
                          {product.name} £{product.price}
                        </Typography>
                      </CardContent>
                    </CardActionArea>
                  </Card>
                </Grid>
              ))}
          </Grid>
        </Container>
      </Box>
    </div>
    </>
  );
};

export default ProductList;
